/* stylelint-disable scss/operator-no-newline-after */
@use 'sass:color';
@use 'sass:math';
@use 'colors';

// Header height
$header-height: 64px;

// Z-INDEX
$modal-z-index: 90;

// BORDER-RADIUS
$border-radius-s: 2px;
$border-radius-m: 3px;
$border-radius-l: 4px;
$border-radius-xl: 8px;
$border-radius-xxl: 12px;
$border-radius-xxxl: 16px;
$font-family-base: 'Inter var', 'Inter', 'Helvetica Neue', Arial, sans-serif;
$font-family-icons: 'fontello';

// FONT-SIZE
$font-size-xxs: 10px;
$font-size-xs: 12px;
$font-size-s: 14px;
$font-size-m: 16px;
$font-size-l: 18px;
$font-size-xl: 19.5px;
$font-size-xxl: 25px;
$font-size-20: 20px;
$font-size-22: 22px;

// UI - Button
$button-font-size: $font-size-l;
$button-font-weight: 600;
$button-letter-spacing: 0;
$button-base-padding: 12px;
$button-padding-ratio: 3;
$button-padding-ratio-extra-small: 2;
$button-padding-ratio-extra-large: 2.6;

// Z-indexes:
$input-addon-z-index: 10;
$carousel-arrows-z-index: 5000;
$block-splash-z-index: 6000;
$tooltip-under-modal-z-index: 9999;
$modal-overlay-z-index: 10000;
$modal-content-z-index: 10001;
$modal-close-btn-z-index: 10002;
$tooltip-z-index: 10002;
$google-maps-autocomplete-z-index: 10002;

// Borders
$border-color: colors.$gray-e0;
$border-radius: 8px;

// Spacing Variables
$brmSize: 16;

// REM variables
$sp-2: math.div(2, $brmSize) + brm; // 2px
$sp-4: math.div(4, $brmSize) + brm; // 4px
$sp-6: math.div(6, $brmSize) + brm; // 6px
$sp-8: math.div(8, $brmSize) + brm; // 8px
$sp-10: math.div(10, $brmSize) + brm; // 10px
$sp-12: math.div(12, $brmSize) + brm; // 12px
$sp-16: math.div(16, $brmSize) + brm; // 16px 1brm
$sp-18: math.div(18, $brmSize) + brm; // 18px
$sp-20: math.div(20, $brmSize) + brm; // 20px
$sp-24: math.div(24, $brmSize) + brm; // 24px
$sp-28: math.div(28, $brmSize) + brm; // 28px
$sp-32: math.div(32, $brmSize) + brm; // 32px 2brm
$sp-40: math.div(40, $brmSize) + brm; // 40px
$sp-48: math.div(48, $brmSize) + brm; // 48px 3brm
$sp-56: math.div(56, $brmSize) + brm; // 56px
$sp-64: math.div(64, $brmSize) + brm; // 64px 4brm
$sp-80: math.div(80, $brmSize) + brm; // 80px 5brm
$sp-145: math.div(145, $brmSize) + brm; // 145px

// ---------------------------------------------------------------legacy part--------------------------------------------------------------------------------------

// For the internal grid framework
$base-rhythm: 16px;

// God
$golden-ratio: 1.618;

// Grid
$grid-width: 1170px;

// UI - Global
$site-max-width: 1440px;

// Breakpoints
$media-breakpoint-sm: 768px;
$media-breakpoint-md: 970px;
$media-breakpoint-lg: 1170px;

// Events
$event-cover-ratio: math.percentage(math.div(182, 348));

// Typography
$font-family-base: 'Inter var', 'Inter', 'Helvetica Neue', Arial, sans-serif;
$font-family-serif: Georgia, Times, 'Times New Roman', serif;
$font-family-icons: 'fontello';
$font-family-code: 'SFMono-Regular', Roboto Mono, Menlo, Monaco, Consolas,
  'Liberation Mono', 'Courier New', monospace;
$headings-font-family: $font-family-base;
$font-size-extra-small: $base-rhythm - 4px;
$font-size-small: $base-rhythm - 2px;
$font-size-base: $base-rhythm;
$font-size-large: $base-rhythm + 2px;
$font-size-extra-large: $base-rhythm + 6px;
$line-height-base: 1.5;
$text-color: colors.$gray-darker;
$body-bg: colors.$white;

// Typography - rhythm
$font-max-size: 60px !default;
$font-min-size: 8px !default;

// Line heights will be multiples of this rhythm
$font-rhythm: math.div($base-rhythm, 2) !default;

// Line heights will leave at least this much space around characters
$font-min-line-padding: 2px !default;

// This is a visual fix for line-height
$font-line-height-ratio-fix: 1.3;

// Typography - Headings
$font-size-h6: $font-size-base;
$font-size-h5: math.div(20, $brmSize) + brm; // 20px
$font-size-h4: math.div(24, $brmSize) + brm; // 24px
$font-size-h3: math.div(36, $brmSize) + brm; // 36px
$font-size-h2: math.div(50, $brmSize) + brm; // 50px
$font-size-h1: math.div(70, $brmSize) + brm; // 70px
$headings-font-weight: 300;
$headings-color: colors.$gray-darker;
$text-muted: colors.$gray-light;
$abbr-border-color: colors.$gray-light;

// Links
$link-color: colors.$brand-primary;
$link-hover-decoration: underline;

// Hr separator
$hr-space: $base-rhythm;
$hr-border-color: colors.$gray-lighter;

// UI - Border
$box-border-color: colors.$gray-lighter;

// UI - Border-radius
$border-radius-small: 2px;
$border-radius-base: 3px;
$border-radius-large: 4px;
$input-border-radius-small: 4px;
$input-border-radius-base: 6px;
$input-border-radius-large: 8px;
$input-border-radius-xl: 1brm;

// UI - Animation
$transition-time: 0.2s;
$transition-time-short: 0.1s;
$transition-time-long: 0.4s;
$transition-easing: ease-in-out;

// UI - Avatar - natural sizes
$avatar-small: 48px;
$avatar-medium: 104px;
$avatar-large: 200px;

// UI - Avatar - custom sizes
$avatar-extra_small: 35px;
$avatar-mini: 26px;
$avatar-offering-header: 60px;
$avatar-investment-done: 58px;
$avatar-offering-card: 60px;
$avatar-offering-card-small: 48px;
$avatar-influencer-profile-header: 140px;
$avatar-influencer-profile-extra_small: 25px;
$avatar-influencer-group-header: 120px;

// UI - Padding default for inputs / buttons
$vertical-padding-extra-small: 4px;
$vertical-padding-small: 6px;
$vertical-padding-base: 8px;
$vertical-padding-large: 10px;
$vertical-padding-extra-large: 15px;
$input-padding-xl: 57px;
$input-padding-horizontal-small: 10px;
$input-padding-horizontal-base: 16px;
$input-padding-top-base: 9px;
$input-padding-bottom-base: 10px;

// UI - Fix broken font line-height
$font-vertical-shift-extra-small: 1px;
$font-vertical-shift-small: 1px;
$font-vertical-shift-base: 1px;
$font-vertical-shift-large: 1.5px;
$font-vertical-shift-extra-large: 2px;

// UI - Button
$button-font-weight: 600;
$button-letter-spacing: 0;
$button-base-padding: 12px;
$button-padding-ratio: 3;
$button-padding-ratio-extra-small: 2;
$button-padding-ratio-extra-large: 2.6;

// calculate padding / height based on input border (2px)
$vertical-padding-fix: 1;

// Sorry
$button-padding-extra-small-top: $vertical-padding-extra-small +
  $vertical-padding-fix - $font-vertical-shift-extra-small;
$button-padding-extra-small-bottom: $vertical-padding-extra-small +
  $vertical-padding-fix + $font-vertical-shift-extra-small;
$button-padding-extra-small: $button-padding-extra-small-top
  math.round($vertical-padding-extra-small * $button-padding-ratio-extra-small)
  $button-padding-extra-small-bottom;
$button-padding-small-top: $vertical-padding-small + $vertical-padding-fix -
  $font-vertical-shift-small;
$button-padding-small-bottom: $vertical-padding-small + $vertical-padding-fix +
  $font-vertical-shift-small;
$button-padding-small: $button-padding-small-top
  math.round($vertical-padding-small * $button-padding-ratio)
  $button-padding-small-bottom;
$button-padding-base-top: $vertical-padding-base + $vertical-padding-fix -
  $font-vertical-shift-base;
$button-padding-base-bottom: $vertical-padding-base + $vertical-padding-fix +
  $font-vertical-shift-base;
$button-padding-base: $button-padding-base-top
  math.round($vertical-padding-base * $button-padding-ratio)
  $button-padding-base-bottom;
$button-padding-large-top: $vertical-padding-large + $vertical-padding-fix -
  $font-vertical-shift-large;
$button-padding-large-bottom: $vertical-padding-large + $vertical-padding-fix +
  $font-vertical-shift-large;
$button-padding-large: $button-padding-large-top
  math.round($vertical-padding-large * $button-padding-ratio)
  $button-padding-large-bottom;
$button-padding-extra-large-top: $vertical-padding-extra-large +
  $vertical-padding-fix - $font-vertical-shift-extra-large;
$button-padding-extra-large-bottom: $vertical-padding-extra-large +
  $vertical-padding-fix + $font-vertical-shift-extra-large;
$button-padding-extra-large: $button-padding-extra-large-top
  math.round($vertical-padding-extra-large * $button-padding-ratio-extra-large)
  $button-padding-extra-large-bottom;

// Inputs
$input-fontsize-base: $font-size-m;
$input-fontsize-small: 16px;
$input-fontsize-large: 25px;
$input-lineheight-base: 1.5;
$input-lineheight-large: 34px;
$input-font-weight: 400;
$input-font-weight-large: 300;
$input-color: colors.$gray-dark;
$placeholder-font-weight: 300;
$placeholder-color: color.adjust(colors.$gray-light, $lightness: 20%);
$input-letter-spacing: 0;
$input-letter-spacing-large: -0.34px;
$input-focus-outline: 0 0 2px 2px rgba((colors.$gray), 0.05);
$input-border-color: color.adjust(colors.$gray-light, $lightness: 40%);
$input-border-color-hover: color.adjust($input-border-color, $lightness: -10%);
$input-border-color-error: colors.$republic-error-new;
$input-background-color: colors.$white;
$input-padding-ratio: 1.2;
$input-padding-small: $vertical-padding-small
  math.round($vertical-padding-small * $input-padding-ratio);
$input-padding-base: $vertical-padding-base
  math.round($vertical-padding-base * $input-padding-ratio);
$input-padding-large: $vertical-padding-large
  math.round($vertical-padding-large * $input-padding-ratio);
$input-padding-extra-large: $vertical-padding-extra-large
  math.round($vertical-padding-extra-large * $input-padding-ratio);

// Top Navigation
$link-border-width: 4px;
$navigation-height: 65px;
$navigation-background: colors.$white;
$navigation-transparent-background: transparent;
$nav-link-color: colors.$gray-2;
$nav-link-transparent-color: colors.$white;
$dropdown-link-height: $base-rhythm * 3;
$navigation-vetical-rule-width: 1px;

// Content Navigation
$content-navigation-height: $base-rhythm * 4;
$breadcrumb-height: $base-rhythm * 3;
$border-width: 1px;

// Investment state
$investment-created: colors.$brand-success;
$investment-completed: colors.$brand-success;
$investment-finalized: colors.$brand-success;
$investment-confirm: colors.$warning;
$investment-cancelled: colors.$danger;
$investment-failed: colors.$danger;
$investment-waitlisted: colors.$warning;
$investment-waitlisted-reserved: colors.$color-purple;
$investment-reserved: colors.$color-purple;
$investment-reservation-confirmed: colors.$color-purple;
$investment-needs_reconfirmation: colors.$danger;

// Table
$table-header-cell-padding: $base-rhythm 0;
$table-cell-padding: math.div($base-rhythm, 2) 0;

// Tags
$tag-horizontal-padding: math.div($base-rhythm, 2);
$tag-vertical-padding: math.div($base-rhythm, 3.2);
$tag-border-width: 1px;

// Dropdown
$dropdown-arrow-size: 8px;
$dropdown-arrow-shadow-size: 1px;
$dropdown-content-radius: 6px;
$dropdown-submenu-content-radius: 3px;
$dropdown-min-width: 220px;

// Panel
$panel-border-radius: 4px;
$panel-border-color: colors.$gray-lighter;
$panel-gray-background-color: color.adjust($panel-border-color, $lightness: 2%);

// Comment
$comment-border-radius: $border-radius-base;
$comment-border-style: 1px solid
  color.adjust(colors.$gray-light, $lightness: 40%);
$comment-box-shadow: 0 0 10px color.adjust(colors.$gray-light, $lightness: 47%);
$comment-reply-background: colors.$gray-fa;

// Campaign agreement height
$campaign-agreement-height: 590px;

// Blog
$blog-card-height: 560px;

// Z-indexes
$request-access-title-z-index: 10;
$input-addon-z-index: 10;
$crowdsafe-navigation-z-index: 10;
$button-group-hover: 10;
$timeline-container-z-index: 10;
$offering-card-cover-tag-z-index: 10;
$plyr-z-index: 10;
$offering-card-ribbon-z-index: 11;
$blog-content-sidebar-z-index: 11;
$content-editable-bg-z-index: 20;
$content-editable-text-z-index: 21;
$offering-page-navigation-z-index: 60;
$offering-page-mtd-photo-z-index: 61;
$scroll-top-button-z-index: 65;
$top-navigation-z-index: 65;
$top-navigation-dropdown-z-index: 64;
$dropdown-z-index: 70;
$beacon-button-z-index: 85;
$mobile-navigation-z-index: 86;
$admin-bar-z-index: 87;
$remodal-overlay-z-index: 90;
$remodal-wrapper-z-index: 95;
$structural-change-popup-z-index: 100;
$cookie-warning-z-index: 10000;

// TEMP code
$redactor-box-z-index: 10;
$offering-pitch-redactor-controls: 11;
$redactor-toolbar-z-index: 30;
$redactor-modal-overlay-z-index: 70;
$redactor-modal-z-index: 80;

// Plugins - Plyr
$plyr-color-main: colors.$brand-primary;
$plyr-video-controls-bg: colors.$gray-base;
$plyr-audio-control-color: colors.$brand-primary;
$plyr-audio-controls-box-shadow: none;
$plyr-range-thumb-shadow: none;

// Offerings
$offering-embed-card-height: 230px;

// Radar - Comment
$radar-comment-border: 1px solid
  color.adjust(colors.$brand-primary, $lightness: 38%);
$radar-comment-background: color.adjust(colors.$brand-primary, $lightness: 43%);

// Mailer
$mailer-body-width: 632px;

@use 'assets/styles/variables' as v;
@use 'assets/styles/colors';
@use 'assets/styles/mixin';

.avatar {
  position: absolute;
  top: -43px;
  background-color: #fff;
  box-shadow: 0 1px 2px 0 rgba((colors.$black), 0.2);

  &.smallAvatar {
    top: -38px;
    overflow: hidden;
    border: 2px solid colors.$white;
    border-radius: v.$border-radius-l;
    box-shadow: none;

    figure {
      &::before {
        border-radius: v.$border-radius-l;
      }
    }
  }
}

.title {
  display: flex;
  align-items: center;
  margin-bottom: v.$sp-8;
  color: inherit;
  cursor: pointer;
  font-size: 24px;
  font-weight: 800;
  letter-spacing: -0.03em;
  line-height: 30px;

  &.smallCardTitle {
    margin-bottom: v.$sp-8;
    font-weight: 600;
    letter-spacing: -0.02em;
    line-height: 30px;

    @include mixin.lineClamp(1);
  }

  .tag {
    margin-left: v.$sp-8;
  }
}

.spotlight {
  position: absolute;
  top: -37px;
  right: 5px;
}

.description {
  min-height: 24px;
  color: colors.$gray-5;
  font-size: v.$font-size-m;
  font-weight: 400;
  letter-spacing: -0.01em;
  line-height: 24px;

  @include mixin.lineClamp(3);

  &.smallCardDescription {
    min-height: unset;
    margin-bottom: 8px;
    color: colors.$gray-mid;

    @include mixin.lineClamp(2);
  }
}

.regulationEntityText {
  height: 14px;
  margin-top: auto;
  color: colors.$grey-d3;
  font-size: 10px;
  letter-spacing: 0.005em;
  line-height: 14px;
  opacity: 1;
  transition: opacity 150ms ease-in, height 150ms ease-in;
}

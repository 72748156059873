@use 'assets/styles/variables' as v;
@use 'assets/styles/colors';

.terms {
  position: absolute;
  right: 0;
  bottom: 306px;
  left: 0;
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
  background: colors.$white;
  border-bottom-left-radius: v.$border-radius-l;
  border-bottom-right-radius: v.$border-radius-l;
  color: colors.$gray-2;
  font-size: v.$font-size-m;
  list-style: none;
  opacity: 0;
  text-transform: lowercase;
  visibility: hidden;

  & > li {
    padding: 0 v.$sp-24;
    border-top: 1px solid colors.$gray-e;
    line-height: 43px;

    &.raised {
      display: flex;
      align-items: center;
    }

    &:not(:last-child) {
      margin-bottom: 0;
    }
  }

  &.smallCardTerms {
    position: unset;
    font-size: v.$font-size-s;
    line-height: 22px;
    opacity: 1;
    text-transform: inherit;
    visibility: visible;
  }

  &.smallCardTerms > li {
    padding: 0 v.$sp-20;
    border-top: none;
    margin-bottom: 8px;
    line-height: inherit;
  }
}

.ellipsisText {
  overflow: hidden;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.smallCardTerms .ellipsisText {
  flex: 1 0 54%;
  color: colors.$gray-mid;
}

.strikeThrough {
  color: initial;
  text-decoration: line-through;
}

.specialTerm {
  color: colors.$danger;
}

.term {
  display: flex;
  align-items: center;
}

.leftPart {
  display: flex;
  align-items: center;
  font-weight: 600;
  text-transform: capitalize;
}

.smallCardTerms .leftPart {
  flex: 1 0 46%;
}

.tooltipWrapper {
  margin-left: v.$sp-4;
}

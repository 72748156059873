@use 'assets/styles/variables' as v;
@use 'assets/styles/colors';

.badgeWrapper {
  position: absolute;
  z-index: 10;
  top: 6px;
  left: 6px;

  &.isSmallCard {
    top: 8px;
    left: 8px;
  }
}

.badgeWrapperInner {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  margin-right: 32px;
  gap: 8px;
  line-height: normal;

  .isSmallCard & {
    margin-right: 36px;
  }
}

.tooltip {
  padding: v.$sp-8;
  text-align: center;
}

.badgeLink {
  color: colors.$white;

  &:hover {
    color: colors.$white;
  }
}

@use 'sass:color';

$brand-primary: #0049ff;

// Naming is taken from http://chir.ag/projects/name-that-color
$silver: #c4c4c4;
$white: #fff;
$green: #19c157;
$black: #000;
$info: $brand-primary;
$warning: #ffb300;
$success: $green;
$danger: #f83a5c;
$color-gold: #e4b369;
$real-estate: #0038ff;
$video-games: #9e00ff;
$crypto: #00db58;
$coral: #f46363;

/* Colors from new designs */
$black-80: #1a1a1a;
$gray-80: #323232;
$gray-70: #777;
$gray-60: #7c7c7c;
$gray-40: #959595;
$gray-20: #c7c7c7;
$color-gray-1: #111;
$gray-2: #222;
$color-gray-3: #333;
$gray-4: #444;
$gray-5: #555;
$gray-6: #666;
$gray-7: #777;
$gray-mid: #818695;
$gray-8: #888;
$gray-9: #999;
$gray-a: #aaa;
$gray-b: #bbb;
$gray-c: #ccc;
$color-gray-d: #ddd;
$gray-e: #eee;
$grey-d3: #d3d4d8;
$grey-d8: #d8d8d8;
$gray-e0: #e0e0e0;
$gray-e9: #e9e9e9;
$gray-fa: #fafafa;
$gray-fd: #fdfdfd;
$gray-f3: #f3f3f3;
$gray-fc: #fcfcfc;
$gray-f2: #f2f2f2;
$gray-f7: #f7f7f7;
$gray-78: #7887a5;
$gray-9a: #9a9fb0;
$text-gray-light: #b0b3bf;
$text-gray-middle: #818695;
$white: #fff;
$republic-blue: $brand-primary;
$republic-blue-hover: #6490fd;
$republic-light-blue-hover: #336dff;
$republic-badge-green: #00cc17;
$c-republic-pink: #fe2d6c;
$republic-error: #f00;
$republic-error-new: #fa5757;
$c-light-blue: #c7e4ff;
$republic-blue-push: #0039c6;
$republic-orchid: #b655e4;
$secondaries-blue: #1560fa;
$disabled: $gray-20;
$orange-ed: #ed7349;
$violet-8d: #8d7bf7;
$beige: #f9ddbc;
$yellow-e3: #e3f37d;

$COLORS: (
  'black': $black,
  'black80': $black-80,
  'white': $white,
  'gray80': $gray-80,
  'gray70': $gray-70,
  'gray60': $gray-60,
  'gray40': $gray-40,
  'gray20': $gray-20,
  'gray2': $gray-2,
  'gray4': $gray-4,
  'gray5': $gray-5,
  'gray6': $gray-6,
  'gray8': $gray-8,
  'gray9': $gray-9,
  'gray-e0': $gray-e0,
  'gray-f2': $gray-f2,
  'gray-f7': $gray-f7,
  'gray-78': $gray-78,
  'gray-light': $text-gray-light,
  'disabled': $disabled,
  'blue': $republic-blue,
  'blueHover': $republic-blue-hover,
  'blueLight': $c-light-blue,
  'bluePush': $republic-blue-push,
  'green': $green,
  'warning': $warning,
  'pink': $c-republic-pink,
  'error': $republic-error,
  'danger': $danger,
);

// Colors - Primary
$brand-primary: $brand-primary;
$brand-light-blue: #ceeaff;
$brand-dark-blue: #1c4082;
$brand-darker-blue: #0f2348;
$brand-darkest-blue: #161d2d;

// Colors - State
$brand-success: $green;
$brand-info: $brand-primary;
$brand-warning: $warning;
$brand-danger: $danger;
$gray-base: $black;

// Colors - Gray
$gray-darker: color.adjust($gray-base, $lightness: 13.5%);
$gray-dark: color.adjust($gray-base, $lightness: 20%);
$gray: color.adjust($gray-base, $lightness: 33.5%);
$gray-light: color.adjust($gray-base, $lightness: 46.7%);
$gray-lighter: color.adjust($gray-base, $lightness: 93.5%);

// Colors - Others
$color-pink: $c-republic-pink;
$dropzone-label: #f4f4f4;
$color-purple: #d241cc;
$dark-purple: #9241d2;

// Colors - Other brands
$brand-facebook: #1778f2;
$brand-twitter: #55acee;
$brand-telegram: #08c;
$brand-linkedin: #007bb5;
$brand-angellist: $gray-2;
$brand-instagram: #f77737;
$brand-youtube: #ff0302;
$brand-pinterest: #bd081c;
$brand-meet-the-drapers: #0f174c;
$brand-meet-the-drapers-light: #081ebd;
$brand-nyc-campaign-yellow: #f8ff55;
$brand-nyc-campaign-turquoise: #4cf7d6;
$brand-nyc-campaign-pink: #ff5a98;
$brand-fig: #8450e0;
$brand-whitelabel: $gray-80;
$brand-real-estate: #aef900;
$brand-compound: #161c28;
$brand-compound-green: $brand-real-estate;
$brand-real-estate-blue: #161c28;
$brand-apple: $black;
$brand-nextseed: #75c696;
$brand-discord: #7289d9;
$brand-medium: $green;

// Crypto
$brand-crypto: $brand-darkest-blue;
$crypto-border-color: #45484f;

// Note
$brand-note: #1a1f4e;

// Badges
$badge-investor: #ffbf40;
$badge-active-investor: #4ceaff;
$badge-founder: #c800ff;
$badge-value-add-investor: $brand-primary;

//Teaser mobile preview
$mobile-background: #7371ee;
$mobile-gradient: #a1d9d6;
$mobile-camera: #2d4d76;

//Deal Updates
$deal-update-action-required: #ee5555;
$deal-update-success: #2cc557;
$deal-update-confirm: #673de0;

//Global search
$search-offering-live: #2cc557;
$search-offering-coming-soon: #6d2ac3;

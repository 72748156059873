@use 'assets/styles/variables' as v;
@use 'assets/styles/colors';

.follow {
  width: 28px;
  height: 28px;
  padding: 0;
  border: none;
  appearance: none;
  background: transparent;
  cursor: pointer;
  filter: drop-shadow(0 3px 3px rgb(0 0 0 / 15%));
  line-height: 0;
}

.followStarIcon {
  display: none;

  &.following {
    display: initial;
  }
}

.notFollowedStarIcon {
  display: none;

  &.notFollowing {
    display: initial;
  }
}

.isSmallCardFollow {
  position: relative;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: colors.$white;
  opacity: 0.95;
  transition: opacity 250ms ease-in;

  & .followStarIcon {
    position: absolute;
    top: 50%;
    left: 50%;
    display: none;
    transform: translate(-50%, -50%);
    transform-origin: center;

    &.isFollowing {
      display: block;
    }
  }

  & .notFollowedStarIcon {
    position: absolute;
    top: 50%;
    left: 50%;
    display: none;
    transform: translate(-50%, -50%);

    &.isNotFollowing {
      display: block;
    }
  }

  &:hover {
    opacity: 1;
    transition: opacity 250ms ease-out;
  }
}

.icon {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 28px;
  height: 28px;
  transform: translate(-50%, -50%);

  & > path {
    fill: colors.$white;
    stroke: colors.$gray-60;
    stroke-width: 2;
    transition: stroke 0.1s ease-in-out, fill 0.1s ease-in-out;
  }

  &:hover > path {
    stroke: colors.$republic-blue;
  }

  &.following > path {
    fill: colors.$warning;
    stroke: colors.$warning;
  }

  &.following:not(.justFollowed):hover > path {
    fill: colors.$white;
  }

  &.isSmallCardFollow {
    width: 24px;
    height: 24px;
  }
}

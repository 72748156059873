@use 'assets/styles/variables' as v;
@use 'assets/styles/colors';

.main {
  &.pointer {
    cursor: pointer;
  }

  & rect,
  & g {
    transition: fill 0.1s ease-in;
  }

  &:hover {
    & rect {
      fill: colors.$gray-e0;
    }

    & path {
      fill: colors.$gray-2;
    }
  }

  &.leftMarginDefault {
    margin-left: v.$sp-8;
  }
}

@use 'assets/styles/variables' as v;
@use 'assets/styles/colors';

.main.main {
  display: inline-block;
  padding: v.$sp-6 v.$sp-8 7px;
  border: 1px solid rgba((colors.$black), 0.1);
  border-radius: v.$border-radius-base;
  color: rgba((colors.$black), 0.6);
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.02em;
  line-height: 1em;
  text-transform: uppercase;
  transition: 0.2s ease-in-out;
  transition-property: color, border-color, background-color, opacity;
  vertical-align: top;

  &.small {
    padding: v.$sp-4 v.$sp-6 5px;
    font-size: 10px;
  }

  &.campaign {
    border-color: colors.$brand-primary;
    color: colors.$brand-primary;
    font-size: 12px;

    &.small {
      padding: 3px v.$sp-6 v.$sp-4;
      font-size: 11px;
    }
  }

  &.message {
    padding: v.$sp-4 v.$sp-6 5px;
    border: none;
    color: colors.$white;
    font-size: 11px;
  }

  &.whiteText {
    color: colors.$white;
  }

  &.success {
    border: 0;
    background-color: colors.$green;
    color: colors.$white;
  }

  &.warning {
    border: 0;
    background-color: colors.$warning;
    color: colors.$white;
  }

  &.danger {
    border: 0;
    background-color: colors.$republic-error;
    color: colors.$white;
  }

  &.noBorder {
    border-color: transparent;
  }
}

.main.color {
  border-color: transparent;
}

/* stylelint-disable scss/operator-no-newline-after */
@use 'sass:list';
@use 'sass:map';
@use 'sass:string';
/* stylelint-disable media-feature-name-no-unknown */
/* stylelint-disable scss/at-if-no-null */

// xs - mobile (0 - 767px)
// sm - tablet (768px - 969px)
// md - small desktop (970px - 1170px)
// lg - big desktop (1170px - ...)

// -------- USAGE ---------

// up(sm) => tablet & bigger screens
// down(sm) => tablet & smaller screens

// stylelint-disable-next-line scss/at-import-no-partial-leading-underscore
@use './_variables' as v;

$media-breakpoints: (
  xs: 0,
  sm: v.$media-breakpoint-sm,
  md: v.$media-breakpoint-md,
  lg: v.$media-breakpoint-lg,
);
$media-current-descriptor: '';

@mixin only($size) {
  $range: media-range($size);

  // Every size has a min-width (might be 0)
  @media all and (min-width: list.nth($range, 1)) {
    @if list.nth($range, 2) == null {
      @content;
    } @else {
      @media (max-width: list.nth($range, 2)) {
        @content;

        // All but the largest also have a max-width
      }
    }
  }
}

@mixin down($size) {
  $max: list.nth(media-range($size), 2);
  @media all {
    @if $max == null {
      @content;
    } @else {
      @media (max-width: $max) {
        @content;
      }
    }
  }
}

@mixin up($size) {
  $min: list.nth(media-range($size), 1);
  @media all and (min-width: $min) {
    @content;
  }
}

// Outputs content multiple times, once in each media context, setting the
// $media-current-descriptor global as it goes to give "XsOnly", "SmDown",
// "MdUp", etc.
@mixin media-each-breakpoint {
  $initial-descriptor: $media-current-descriptor;

  // Output content without any descriptor
  $media-current-descriptor: '';
  @content;
  @each $size, $breakpoint in $media-breakpoints {
    $range: media-range($size);

    // All ranges get an "Only" descriptor
    @include only($size) {
      $media-current-descriptor: '#{media-class-descriptor($size)}Only';
      @content;
    }

    // All but the bottom range gets a "Down" descriptor
    @if list.nth($range, 1) > 0 {
      @include down($size) {
        $media-current-descriptor: '#{media-class-descriptor($size)}Down';
        @content;
      }
    }

    // All but the top range gets an "Up" descriptor
    @if list.nth($range, 2) != null {
      @include up($size) {
        $media-current-descriptor: '#{media-class-descriptor($size)}Up';
        @content;
      }
    }
  }

  $media-current-descriptor: $initial-descriptor;
}

@mixin media-each-breakpoint-up {
  $initial-descriptor: $media-current-descriptor;

  @each $size, $breakpoint in $media-breakpoints {
    @include up($size) {
      $media-current-descriptor: media-class-descriptor($size);
      @content;
    }
  }

  $media-current-descriptor: $initial-descriptor;
}

// Gets a two-element list with the (min, max) pixel values for the size
// with the given name (i.e.  media-range(sm) -> (768, 969)).
// If the range has no maximum, the second element will be null.
@function media-range($size) {
  $min: map.get($media-breakpoints, $size);
  $max: null;
  @each $otherSize, $breakpoint in $media-breakpoints {
    @if $breakpoint > $min and ($max == null or $breakpoint <= $max) {
      $max: $breakpoint - 1px;
    }
  }
  @return ($min, $max);
}

// Converts xs -> Xs, md -> Md, etc, for use in CSS class names.
@function media-class-descriptor($size) {
  @return string.to-upper-case(string.slice($size, 1, 1)) +
    string.slice($size, 2);
}

@mixin media-retina {
  @media (minpixel-ratio: 2),
    (min-device-pixel-ratio: 2),
    (device-pixel-ratio: 2) {
    @content;
  }
}

@mixin media-print {
  @media print {
    @content;
  }
}

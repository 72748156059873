@use 'sass:color';
@use 'assets/styles/variables' as v;
@use 'assets/styles/colors';

@mixin link($color) {
  color: $color;
  text-decoration: none;

  &:hover {
    color: color.adjust($color, $lightness: 10%);
    text-decoration: underline;
  }

  &:active {
    color: color.adjust($color, $lightness: 10%);
  }

  &:focus {
    color: color.adjust($color, $lightness: 10%);
  }
}

@mixin hoverTranslateY($pxCount) {
  transition: transform 0.2s ease-in-out;

  &:active,
  &:hover,
  &:focus {
    box-shadow: 0 3px 8px colors.$gray-f2;
    color: inherit;
    text-decoration: none;
    transform: translateY($pxCount);
  }
}

@mixin lineClamp($lines) {
  // FIXME:
  /* stylelint-disable property-no-vendor-prefix */
  /* stylelint-disable value-no-vendor-prefix */
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $lines;
  /* stylelint-enable property-no-vendor-prefix */
  /* stylelint-enable value-no-vendor-prefix */
}

@mixin textEllipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

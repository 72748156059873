@use 'assets/styles/variables' as v;
@use 'assets/styles/media';
@use 'assets/styles/colors';

.footer {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  padding: v.$sp-18 v.$sp-24;
  margin-top: v.$sp-32;
  transition: transform 0.2s ease-in-out;

  @include media.up(md) {
    margin-top: 0;
  }

  &.smallCardFooter {
    padding: v.$sp-16;
  }
}

.location {
  display: none;
  box-sizing: border-box;
  margin-bottom: v.$sp-4;
  color: colors.$gray-9;
  cursor: pointer;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: -0.01em;
  line-height: 24px;
  overflow-wrap: break-word;
  text-size-adjust: 100%;

  @include media.up(md) {
    display: block;
  }
}

.cards {
  display: block;
  overflow: hidden hidden;
  height: 22px;
  box-sizing: border-box;
  margin-top: 0;
  color: rgba((colors.$black), 0.65);
  cursor: pointer;
  font-size: 18px;
  font-weight: 300;
  line-height: 24px;
  overflow-wrap: break-word;
  text-size-adjust: 100%;

  .tag {
    padding: 5px 6px;
    border: 1px solid rgb(0 0 0 / 10%);
    margin-right: v.$sp-4;
  }
}

.regulationEntityText {
  margin-top: 8px;
  color: colors.$gray-b;
  font-size: 10px;
  font-weight: 400;
  letter-spacing: 0.005em;
  line-height: 14px;
  opacity: 1;
  transition: opacity 150ms ease-in, height 150ms ease-in;
}
